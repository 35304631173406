import { ChangeEvent, Fragment, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { FormikValues, Field, Form, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';

import { Grid } from '../../components/Grid';
import { Card } from '../../components/common/Card';
import { Input } from '../../components/common/Input';
import { Select } from '../../components/common/Select';
import { Title } from '../../components/common/Title';
import { Switch } from '../../components/common/Switch';
import { CustomFormik } from '../../components/CustomFormik';
import { AppContext } from '../../components/Context';
import { REGEX, ROUTE, STEP_CODE, SUPPORTED_UPLOAD_FILE_TYPES } from '../../constants';
import {
    parseValidationErrors,
    subtractDate,
    getCountriesSelectList,
    getOwner,
    getSelectValueByCode,
    getIdentificatorSelectList,
    getSexSelectList,
    getHigherStep,
    getOwnerParticipant,
    isDevelopment,
    getBase64,
    getBase64FileContent,
    getToken,
} from '../../utils';
import {
    callValidateClientData,
    callValidateOnlinePolicyPlSanction,
    callValidatePersonalInformation,
} from '../../apis/validations';
import { ErrorType, ValidationResultType } from '../../types';
import GlobalError from '../../components/GlobalError';
import { config } from '../../config';
import {
    ModelProps,
    MojeIdGetClientDataResponse,
    ParticipantPersonProps,
    PersonalIdentificationFullProps,
} from '../../types/model';
import { IdentificationType } from '../../types/enums/IdentificationType';
import { useAppNavigate, useContextHelpers } from '../../utils/hooks';
import { callValidateClientPersonalInformationExist } from '../../apis/validations/validateClientPersonalInformationExist';
import { DetectFormikChanged } from '../../components/common/DetectFormikChanged';
import DateInput from '../../components/common/DateInput';
import DragAndDropUpload from '../../components/common/DragAndDropUpload';
import UploadedFiles from './UploadedFiles';
import { callAttachDocument } from '../../apis/documents';

interface PersonalDataFormType {
    name: string;
    surname: string;
    identificatorType?: { value: string | number; label: string } | null;
    identificatorNumber: string;
    sex: { value: string | number; label: string } | null;
    // pep
    clientIsPep: boolean;
    // narození a občanství
    birthDate?: string;
    birthState: { value: string | number; label: string };
    birthCity: string;
    citizenship: { value: string | number; label: string };
    secondCitizenship: { value: string | number; label: string } | null;
    // trvalá adresa
    city: string;
    street: string;
    houseNumber: string;
    postalCode: string;
    country: { value: string | number; label: string };
    // kontaktní adresa
    contactStreet: string;
    contactHouseNumber: string;
    contactCity: string;
    contactPostalCode: string;
    contactCountry: { value: string | number; label: string };
    // jiná kontaktní adresa
    contactAddressDifferent: boolean;
    // iban
    accountNumber: string;
}

interface PersonalDataFormProps {
    mojeIdClientData: MojeIdGetClientDataResponse | null;
    pepFiles: any[];
    disabledFromMojeId: boolean;
    onSubmitting: (submitting: boolean) => void;
    onSetErrorSanctionRegion: (value: boolean) => void;
    onSetPepFiles: (files: SetStateAction<any[]>) => void;
}

const PersonalDataForm = ({
    mojeIdClientData,
    pepFiles,
    disabledFromMojeId,
    onSubmitting,
    onSetErrorSanctionRegion,
    onSetPepFiles,
}: PersonalDataFormProps) => {
    const { t } = useTranslation();
    const token = getToken();
    const { initData, ...ctx } = useContext(AppContext);
    const { isF2F, isRemote } = useContextHelpers();
    const { navigateTo } = useAppNavigate();
    const [errors, setErrors] = useState<ErrorType[]>([]);
    const [errorSanctionRegion, setErrorSanctionRegion] = useState(false);
    const data = ctx.currentModel;
    const ownerId = data ? getOwner(data)?.ParticipantExternalId : null;
    const idList = getIdentificatorSelectList(initData);
    const sexList = getSexSelectList(initData);
    const ownerParticipant =
        data && ownerId
            ? (getOwnerParticipant(data, ownerId) as ParticipantPersonProps<PersonalIdentificationFullProps>) ?? null
            : null;
    const otherParticipants = data?.Participants.filter((p) => p && p.ExternalId !== ownerId);
    const countriesSelectData = getCountriesSelectList(initData) || [];
    const myFormikRef = useRef<FormikProps<any>>(null);

    // @TODO: We will get them from API
    const idDetail = ownerParticipant?.Identification?.PersonalIdentificationDetail;
    const permanentAddress = ownerParticipant?.Identification?.PermanentAddress;
    const contactAddress = ownerParticipant?.Identification?.ContactAddress;

    const idCard = ownerParticipant?.Identification.IdCard || null;

    // data z mojeId
    const mojeIdPersonalData = mojeIdClientData && mojeIdClientData?.PersonalIdentification;
    const mojeIdPersonalAddress =
        mojeIdClientData && mojeIdClientData?.PermanentAddresses?.length > 0
            ? mojeIdClientData.PermanentAddresses[0]
            : null;
    const mojeIdContactAddress =
        mojeIdClientData && mojeIdClientData?.ContactAddresses?.length > 0
            ? mojeIdClientData.ContactAddresses[0]
            : null;
    const mojeIdBankAccount =
        mojeIdClientData && mojeIdClientData?.BankAccounts?.length > 0 && mojeIdClientData.BankAccounts[0].Iban
            ? mojeIdClientData.BankAccounts[0]
            : null;

    const [hasAnotherAddress, setHasAnotherAddress] = useState(
        !!(contactAddress?.CityName ?? mojeIdContactAddress?.CityName) || false
    );

    const finalIban = ownerParticipant?.Identification?.BankAccount?.Iban ?? mojeIdBankAccount?.Iban ?? '';

    const initialValues: PersonalDataFormType = {
        name: idDetail?.FirstName ?? mojeIdPersonalData?.FirstName ?? (isDevelopment() ? 'Anna' : ''),
        surname: idDetail?.LastName ?? mojeIdPersonalData?.LastName ?? (isDevelopment() ? 'Nowak' : ''),
        identificatorType: getSelectValueByCode(
            idList,
            mojeIdPersonalData?.PersonalIdentifierType ?? IdentificationType.Pesel
        ),
        identificatorNumber:
            idDetail?.PersonalIdentifier ??
            mojeIdPersonalData?.PersonalIdentifier ??
            (isDevelopment() ? '74060368943' : ''),
        sex: getSelectValueByCode(sexList, mojeIdPersonalData?.Sex ?? ownerParticipant?.Identification?.Sex) ?? null,
        // pep
        clientIsPep: ownerParticipant?.IsPep ?? false,
        // narození a občanství
        birthDate: ownerParticipant?.Identification?.BirthDt ?? mojeIdPersonalData?.BirthDt,
        birthState:
            getSelectValueByCode(
                countriesSelectData,
                idDetail?.BirthCountryCode ?? mojeIdPersonalData?.BirthCountryCode
            ) ?? countriesSelectData?.[0],
        birthCity: idDetail?.Birthplace ?? mojeIdPersonalData?.Birthplace ?? (isDevelopment() ? 'Warsaw' : ''),
        citizenship:
            getSelectValueByCode(
                countriesSelectData,
                idDetail?.CitizenshipCode ?? mojeIdPersonalData?.CitizenshipCode
            ) ?? countriesSelectData?.[0],
        secondCitizenship:
            getSelectValueByCode(
                countriesSelectData,
                idDetail?.SecondCitizenshipCode ?? mojeIdPersonalData?.SecondCitizenshipCode
            ) ?? null,
        // trvalá adresa
        city: permanentAddress?.CityName ?? mojeIdPersonalAddress?.CityName ?? (isDevelopment() ? 'Warsawa' : ''),
        street: permanentAddress?.StreetName ?? mojeIdPersonalAddress?.StreetName ?? (isDevelopment() ? 'Default' : ''),
        houseNumber: permanentAddress?.HouseNo ?? mojeIdPersonalAddress?.HouseNo ?? (isDevelopment() ? '1' : ''),
        postalCode: permanentAddress?.Zip ?? mojeIdPersonalAddress?.Zip ?? (isDevelopment() ? '00-001' : ''),
        country:
            getSelectValueByCode(
                countriesSelectData,
                permanentAddress?.CountryCode ?? mojeIdPersonalAddress?.CountryCode
            ) ?? countriesSelectData?.[0],
        // kontaktní adresa
        contactStreet: contactAddress?.StreetName ?? mojeIdContactAddress?.StreetName ?? '',
        contactHouseNumber: contactAddress?.HouseNo ?? mojeIdContactAddress?.HouseNo ?? '',
        contactCity: contactAddress?.CityName ?? mojeIdContactAddress?.CityName ?? '',
        contactPostalCode: contactAddress?.Zip ?? mojeIdContactAddress?.Zip ?? '-',
        contactCountry:
            getSelectValueByCode(
                countriesSelectData,
                contactAddress?.CountryCode ?? mojeIdContactAddress?.CountryCode
            ) ?? countriesSelectData?.[0],
        // jiná kontaktní adresa
        contactAddressDifferent: hasAnotherAddress,
        // iban
        accountNumber: finalIban,
    };

    const formSchema: any = Yup.object().shape({
        name: Yup.string().required().min(2),
        surname: Yup.string().required().min(2),
        identificatorNumber: Yup.string()
            .peselFormat(t('common.formErrors.wrongPesel'))
            .compareBirthDtPesel('birthDate', t('common.formErrors.peselDontMatchBirthDt'))
            .required(),
        identificatorType: Yup.object().required(),
        sex: Yup.object().required(),
        birthDate: Yup.date()
            .required()
            .min(subtractDate(config.MAX_AGE, 'years'))
            .max(subtractDate(config.MIN_AGE, 'years')),
        birthState: Yup.object().required(),
        birthCity: Yup.string().required().min(2),
        citizenship: Yup.object().required(),
        secondCitizenship: Yup.object().nullable(),
        city: Yup.string().required().min(2),
        street: Yup.string().required(),
        houseNumber: Yup.string().required(),
        postalCode: Yup.string()
            .required()
            .matches(new RegExp(REGEX.POLISH_POST_REGEX), t('common.formErrors.wrongPostalCode') || ''),
        country: Yup.object().required(),
        contactStreet: hasAnotherAddress ? Yup.string().required() : Yup.string(),
        contactHouseNumber: hasAnotherAddress ? Yup.string().required() : Yup.string(),
        contactCity: hasAnotherAddress ? Yup.string().required().min(2) : Yup.string().min(2),
        contactPostalCode: hasAnotherAddress
            ? Yup.string()
                  .required()
                  .matches(new RegExp(REGEX.POLISH_POST_REGEX), t('common.formErrors.wrongPostalCode') || '')
            : Yup.string(),
        contactCountry: hasAnotherAddress ? Yup.object().required() : Yup.object(),
        accountNumber: Yup.string().matches(
            new RegExp(REGEX.POLISH_BANK_NUMBER),
            t('common.formErrors.wrongBankAccountNumber') || ''
        ),
    });

    const updateData = (v: FormikValues, withConfirmationStep: boolean = false): ModelProps => {
        let newModel = { ...data } as ModelProps;

        if (ownerParticipant && v && data) {
            // Identification
            ownerParticipant.Identification = {
                ...ownerParticipant.Identification,
                Sex: v?.sex?.value ?? ownerParticipant.Identification.Sex,
            };

            // Personal information
            ownerParticipant.Identification.PersonalIdentificationDetail = {
                ...ownerParticipant.Identification.PersonalIdentificationDetail,
                BirthCountryCode: v?.birthState?.value ?? null,
                Birthplace: v?.birthCity,
                CitizenshipCode: v?.citizenship?.value ?? null,
                FirstName: v?.name,
                LastName: v?.surname,
                PersonalIdentifier: v?.identificatorNumber,
                PersonalIdentifierType: v?.identificatorType?.value ?? null,
                SecondCitizenshipCode: v?.secondCitizenship?.value ?? null,
                TitleAfter: '',
                TitleBefore: '',
            };

            // Registered address
            ownerParticipant.Identification.PermanentAddress = {
                ...ownerParticipant.Identification.PermanentAddress,
                CityName: v?.city,
                CountryCode: v?.country?.value ?? null,
                HouseNo: v?.houseNumber,
                StreetName: v?.street,
                Zip: v?.postalCode,
            };

            // Contact address
            if (hasAnotherAddress) {
                if (
                    v?.contactCity ||
                    v?.contactCountry?.value ||
                    v?.contactHouseNumber ||
                    v?.contactStreet ||
                    v?.contactPostalCode
                ) {
                    ownerParticipant.Identification.ContactAddress = {
                        ...ownerParticipant.Identification.ContactAddress,
                        CityName: v?.contactCity ?? '',
                        CountryCode: v?.contactCountry?.value ?? '',
                        HouseNo: v?.contactHouseNumber ?? '',
                        StreetName: v?.contactStreet ?? '',
                        Zip: v?.contactPostalCode ?? '',
                    };
                } else {
                    ownerParticipant.Identification.ContactAddress = null;
                }
            } else {
                ownerParticipant.Identification.ContactAddress = null;
            }

            // Bank account
            if (v?.accountNumber) {
                ownerParticipant.Identification.BankAccount = {
                    ...ownerParticipant.Identification.BankAccount,
                    Iban: v?.accountNumber,
                    Code: '',
                    BankAccountNo: '',
                    Prefix: '',
                };
            } else {
                ownerParticipant.Identification.BankAccount = null;
            }
            ownerParticipant.IsPep = v?.clientIsPep;

            newModel = {
                ...data,
                Participants: [ownerParticipant, ...(otherParticipants ?? [])],
            };

            if (withConfirmationStep) {
                newModel = {
                    ...data,
                    Settings: {
                        ...data.Settings,
                        CurrentStepCode: getHigherStep(data.Settings?.CurrentStepCode, STEP_CODE.PERSONAL_DATA),
                    },
                };
            }

            ctx.setCurrentModel(newModel);
        }

        return newModel;
    };

    const onChangeRegion = () => {
        if (errorSanctionRegion) {
            setErrorSanctionRegion(false);
            onSubmitting(false);
        }
    };

    const handleUploadPepFiles = (files: FileList | null) => {
        if (files) {
            onSetPepFiles((prev) => [...prev, ...Array.from(files)]);
        }
    };

    const handleDeleteFile = (_: File, index: number) => {
        onSetPepFiles((prev) => [...prev].filter((_, i) => i !== index));
    };

    const uploadPepFiles = async () => {
        if (data && token) {
            const ret = await pepFiles.reduce(async (promise, file) => {
                const accumulator = await promise;
                if (file instanceof File) {
                    const fileContent = file && (await getBase64(file));
                    const result = await callAttachDocument({
                        data: {
                            onlinePolicyExternalId: data.ExternalId,
                            attachmentData: getBase64FileContent(fileContent),
                            externalId: uuid(),
                            fileName: file?.name,
                            pageNumber: 1,
                            onlinePolicyAttachmentTypeCode: 'PepQuestionnaire',
                        },
                        token,
                    });
                    accumulator.push(result);
                }
                return accumulator;
            }, Promise.resolve([]));

            return ret;
        }
    };

    useEffect(() => {
        onSetErrorSanctionRegion(errorSanctionRegion);
    }, [onSetErrorSanctionRegion, errorSanctionRegion]);

    return (
        <CustomFormik<PersonalDataFormType>
            initialValues={{
                ...initialValues,
            }}
            customRender
            onSubmit={async (v: any) => {
                const newModel = updateData(v, true);
                setErrorSanctionRegion(false);
                setErrors([]);

                if (v.clientIsPep && isRemote) {
                    ctx.showPopup('pep');
                } else {
                    ctx.showPopup(null);
                    if (token) {
                        onSubmitting(true);

                        // z ibanu odstraním PL
                        if (v?.accountNumber && newModel && ownerId) {
                            const ownerParticipant =
                                newModel && ownerId
                                    ? (getOwnerParticipant(
                                          newModel,
                                          ownerId
                                      ) as ParticipantPersonProps<PersonalIdentificationFullProps>) ?? null
                                    : null;
                            if (ownerParticipant) {
                                ownerParticipant.Identification.BankAccount = {
                                    ...ownerParticipant.Identification.BankAccount,
                                    Iban: ownerParticipant.Identification.BankAccount?.Iban
                                        ? `PL${ownerParticipant.Identification.BankAccount?.Iban?.toUpperCase()
                                              .replaceAll(' ', '')
                                              .replace(new RegExp('^PL'), '')
                                              .substring(0, 26)}`
                                        : '',
                                    Code: '',
                                    BankAccountNo: '',
                                    Prefix: '',
                                };
                            }
                        }

                        const error = [];

                        // sankční země
                        let sanction = null;
                        try {
                            sanction = await callValidateOnlinePolicyPlSanction({
                                data: {
                                    onlinePolicyPlJson: JSON.stringify(newModel),
                                },
                                token,
                            });
                        } catch {}

                        if (isF2F) {
                            const clientPerson = {
                                PersonalIdentification: {
                                    BirthDt: v.birthDate,
                                    BirthCountryCode: v.birthState?.value,
                                    Birthplace: v.birthCity,
                                    CitizenshipCode: v.citizenship?.value,
                                    CurrentAge: ownerParticipant?.Identification.CurrentAge,
                                    FirstName: v.name,
                                    LastName: v.surname,
                                    MiddleName: null,
                                    Sex: v.sex?.value,
                                    TitleBefore:
                                        ownerParticipant?.Identification.PersonalIdentificationDetail?.TitleBefore,
                                    TitleAfter:
                                        ownerParticipant?.Identification.PersonalIdentificationDetail?.TitleAfter,
                                    DeathDt: null,
                                    DeserializationCode: 'ParisShared_PersonalIdentificationDto',
                                    PersonalIdentifier: v.identificatorNumber,
                                    PersonalIdentifierType: v.identificatorType.value,
                                },
                                IdCards: idCard ? [idCard] : [],
                                Contact: {
                                    Email: ownerParticipant?.Contact?.Email,
                                    Phone: ownerParticipant?.Contact?.Phone,
                                    PhonePrefix: {
                                        Prefix: ownerParticipant?.Contact?.PhonePrefix.Prefix,
                                        DeserializationCode: 'ParisShared_PhonePrefixSimpleDto',
                                    },
                                },
                                ClientExternalId: data?.ExternalId,
                            };

                            try {
                                // validace klientských dat
                                const clientValidationResult = await callValidateClientData({
                                    data: {
                                        clientPersonDtoJson: JSON.stringify(clientPerson),
                                    },
                                    token,
                                });

                                const { HasClientByPeselAnyActivePolicyResult } = clientValidationResult?.data || {};

                                if (HasClientByPeselAnyActivePolicyResult?.HasAnyActivePolicy === true) {
                                    const policyNumbers =
                                        HasClientByPeselAnyActivePolicyResult?.ExistingPolicyNumbers.join(', ');

                                    error.push({
                                        code: '',
                                        message: t('pages.contactConfirmation.hasPersonBirthDateAnyActivePolicy', {
                                            policyNumbers,
                                        }),
                                    });
                                }
                            } catch {}
                        }

                        // je v sankčním seznamu
                        if (!sanction || sanction?.data?.IsValid !== true) {
                            setErrorSanctionRegion(true);
                            error.push({
                                code: '',
                                message: t('pages.personalContactInformation.errors.isFromSanctionRegion'),
                            });
                            onSubmitting(false);
                        }

                        // nastavím pole chyb do formuláře
                        setErrors(error);

                        // a nejsou žádné chyby, sankční seznam, aktivní smlouvy
                        if (error.length === 0) {
                            // nahraje pep soubory
                            await uploadPepFiles();

                            // zvaliduje osobní údaje
                            callValidatePersonalInformation({ data: newModel, token })
                                .then((response) => response.data)
                                .then((result: ValidationResultType) => {
                                    if (data) {
                                        ctx.saveCurrentModel(data);
                                    }
                                    if (result.Passed) {
                                        callValidateClientPersonalInformationExist({
                                            data: {
                                                PersonFirstName: v.name,
                                                PersonLastName: v.surname,
                                                PersonEmail: newModel?.Participants?.[0].Contact?.Email ?? '',
                                                PersonPhone: newModel?.Participants?.[0].Contact?.Phone ?? '',
                                                PersonPhonePrefix:
                                                    newModel.Participants?.[0].Contact?.PhonePrefix.Prefix ?? '',
                                                PersonPesel: v.identificatorNumber,
                                            },
                                            token,
                                        })
                                            .then((responsePersonalInformation) => responsePersonalInformation.data)
                                            .then((resultPersonalInformation) => {
                                                if (
                                                    resultPersonalInformation.IsValidByEmailAndPhone &&
                                                    resultPersonalInformation.IsValidByPesel
                                                ) {
                                                    navigateTo(ROUTE.BENEFICIARIES);
                                                } else {
                                                    const error: ErrorType[] = [];
                                                    if (
                                                        resultPersonalInformation.IsValidByEmailAndPhone ===
                                                            undefined ||
                                                        !resultPersonalInformation.IsValidByEmailAndPhone
                                                    ) {
                                                        error.push({
                                                            code: '',
                                                            message: t(
                                                                'pages.personalContactInformation.errors.isValidByEmailAndPhone'
                                                            ),
                                                        });
                                                    }
                                                    if (
                                                        resultPersonalInformation.IsValidByPesel === undefined ||
                                                        !resultPersonalInformation.IsValidByPesel
                                                    ) {
                                                        error.push({
                                                            code: '',
                                                            message: t(
                                                                'pages.personalContactInformation.errors.isValidByPesel'
                                                            ),
                                                        });
                                                    }
                                                    setErrors(error);
                                                    if (isDevelopment()) {
                                                        setTimeout(() => navigateTo(ROUTE.BENEFICIARIES), 10000);
                                                    }
                                                }
                                            });
                                    }
                                    if (!result.Passed) {
                                        setErrors(parseValidationErrors(result));
                                        if (isDevelopment()) {
                                            setTimeout(() => navigateTo(ROUTE.BENEFICIARIES), 10000);
                                        }
                                    }
                                })
                                .finally(() => onSubmitting(false));
                        }
                    }
                }
            }}
            validationSchema={formSchema}
            passedRef={myFormikRef}
        >
            {({ values, setFieldValue }: FormikProps<any>) => (
                <Fragment>
                    {errors && (
                        <GlobalError
                            errors={errors}
                            withoutTitle={errorSanctionRegion && errors.length === 1}
                            data-test="error"
                        />
                    )}

                    <Form noValidate>
                        <DetectFormikChanged onChange={updateData} />
                        <Card className="gap-y-12" data-test="personalDataCard">
                            <div className="flex flex-col justify-center">
                                <Title
                                    tag="h3"
                                    size="md"
                                    fontWeight="medium"
                                    className="mb-6"
                                    data-test="personalDataTitle"
                                >
                                    {t('pages.personalContactInformation.card.titles.personalInformation')}
                                </Title>
                                <Grid>
                                    <Field
                                        name="name"
                                        label={t('common.formFields.name')}
                                        isDisabled={disabledFromMojeId}
                                        isCenter
                                        component={Input}
                                        data-test="name"
                                    />
                                    <Field
                                        name="surname"
                                        label={t('common.formFields.surname')}
                                        isDisabled={disabledFromMojeId}
                                        isCenter
                                        component={Input}
                                        data-test="surname"
                                    />
                                    <Field
                                        component={Select}
                                        name="identificatorType"
                                        label={t('common.formFields.identificatorType')}
                                        isCenter
                                        isSearchable={false}
                                        options={idList}
                                        isDisabled
                                        data-test="identificatorType"
                                    />
                                    <Field
                                        name="identificatorNumber"
                                        label={t('common.formFields.identificatorNumber')}
                                        isDisabled={disabledFromMojeId}
                                        isCenter
                                        component={Input}
                                        maxLength={50}
                                        isStringNumber
                                        data-test="identificatorNumber"
                                    />
                                    <Field
                                        component={Select}
                                        name="sex"
                                        label={t('common.formFields.sex')}
                                        isDisabled={disabledFromMojeId}
                                        isCenter
                                        options={sexList}
                                        data-test="sex"
                                    />
                                    <Field
                                        isDisabled
                                        name="birthDate"
                                        isCenter
                                        label={t('common.formFields.birthDate')}
                                        component={DateInput}
                                        minDate={new Date(subtractDate(config.MAX_AGE, 'years'))}
                                        maxDate={new Date(subtractDate(config.MIN_AGE, 'years'))}
                                        data-test="birthDate"
                                    />
                                    <Field
                                        component={Select}
                                        name="birthState"
                                        label={t('common.formFields.birthState')}
                                        isDisabled={disabledFromMojeId}
                                        isCenter
                                        options={countriesSelectData}
                                        isSearchable
                                        data-test="birthState"
                                    />
                                    <Field
                                        name="birthCity"
                                        label={t('common.formFields.birthCity')}
                                        isDisabled={disabledFromMojeId}
                                        isCenter
                                        component={Input}
                                        data-test="birthCity"
                                    />
                                    <Field
                                        name="citizenship"
                                        options={countriesSelectData}
                                        label={t('common.formFields.citizenship')}
                                        isDisabled={disabledFromMojeId}
                                        isCenter
                                        component={Select}
                                        isSearchable
                                        handleChange={onChangeRegion}
                                        data-test="citizenship"
                                    />
                                    <Field
                                        name="secondCitizenship"
                                        options={countriesSelectData}
                                        label={t('common.formFields.secondCitizenship')}
                                        isDisabled={disabledFromMojeId}
                                        isCenter
                                        component={Select}
                                        isSearchable
                                        isClearable
                                        handleChange={onChangeRegion}
                                        data-test="secondCitizenship"
                                    />
                                </Grid>
                            </div>

                            <div className="flex flex-col justify-center border-b-1 border-purple pb-12">
                                <Title tag="h3" size="md" fontWeight="medium" className="mb-6" data-test="addressTitle">
                                    {t('pages.personalContactInformation.card.titles.registeredAddress')}
                                </Title>
                                <Grid className="mb-12">
                                    <Field
                                        name="street"
                                        label={t('common.formFields.street')}
                                        isDisabled={disabledFromMojeId}
                                        isCenter
                                        component={Input}
                                        data-test="street"
                                    />
                                    <Field
                                        name="houseNumber"
                                        label={t('common.formFields.houseNumber')}
                                        isDisabled={disabledFromMojeId}
                                        isCenter
                                        component={Input}
                                        maxLength={50}
                                        data-test="houseNumber"
                                    />
                                </Grid>
                                <Grid cols={3} className="mb-12">
                                    <Field
                                        name="city"
                                        label={t('common.formFields.city')}
                                        isDisabled={disabledFromMojeId}
                                        isCenter
                                        component={Input}
                                        data-test="city"
                                    />
                                    <Field
                                        name="postalCode"
                                        label={t('common.formFields.postalCode')}
                                        isDisabled={disabledFromMojeId}
                                        isCenter
                                        component={Input}
                                        maxLength={10}
                                        data-test="postalCode"
                                    />
                                    <Field
                                        name="country"
                                        component={Select}
                                        label={t('common.formFields.country')}
                                        isDisabled={disabledFromMojeId}
                                        isCenter
                                        options={countriesSelectData}
                                        isSearchable
                                        handleChange={onChangeRegion}
                                        data-test="country"
                                    />
                                </Grid>

                                <Field
                                    component={Switch}
                                    label={t('common.formFields.contacAddressDifferent')}
                                    name="contacAddressDifferent"
                                    isDisabled={disabledFromMojeId}
                                    checked={hasAnotherAddress}
                                    className="mx-auto"
                                    data-test="contacAddressDifferent"
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        setHasAnotherAddress(e.target.checked);
                                    }}
                                />

                                {hasAnotherAddress && (
                                    <>
                                        <Title
                                            tag="h3"
                                            size="md"
                                            fontWeight="medium"
                                            className="mb-6 mt-12"
                                            data-test="contactAddressTitle"
                                        >
                                            {t('pages.personalContactInformation.card.titles.contactAddress')}
                                        </Title>
                                        <Grid className="mb-12">
                                            <Field
                                                name="contactStreet"
                                                label={t('common.formFields.street')}
                                                isDisabled={disabledFromMojeId}
                                                isCenter
                                                component={Input}
                                                data-test="contactStreet"
                                            />
                                            <Field
                                                name="contactHouseNumber"
                                                label={t('common.formFields.houseNumber')}
                                                isDisabled={disabledFromMojeId}
                                                isCenter
                                                component={Input}
                                                data-test="contactHouseNumber"
                                            />
                                        </Grid>
                                        <Grid cols={3} className="mb-12">
                                            <Field
                                                name="contactCity"
                                                label={t('common.formFields.city')}
                                                isDisabled={disabledFromMojeId}
                                                isCenter
                                                component={Input}
                                                data-test="contactCity"
                                            />
                                            <Field
                                                name="contactPostalCode"
                                                label={t('common.formFields.postalCode')}
                                                isDisabled={disabledFromMojeId}
                                                isCenter
                                                component={Input}
                                                maxLength={10}
                                                data-test="contactPostalCode"
                                            />
                                            <Field
                                                name="contactCountry"
                                                component={Select}
                                                label={t('common.formFields.country')}
                                                isDisabled={disabledFromMojeId}
                                                isCenter
                                                options={countriesSelectData}
                                                isSearchable
                                                data-test="contactCountry"
                                            />
                                        </Grid>
                                    </>
                                )}
                            </div>

                            <div className="flex flex-col justify-center border-b-1 border-purple">
                                <Title
                                    tag="h3"
                                    size="md"
                                    fontWeight="medium"
                                    className="mb-6"
                                    data-test="bankAccountTitle"
                                >
                                    {t('pages.personalContactInformation.card.titles.bankAccount')}
                                </Title>
                                <Field
                                    component={Input}
                                    name="accountNumber"
                                    label={t('common.formFields.bankAccountNumber')}
                                    className="mb-12"
                                    isDisabled={disabledFromMojeId}
                                    isCenter
                                    maxLength={36}
                                    data-test="accountNumber"
                                    onBlur={(e: any) => {
                                        setFieldValue(
                                            e.target.name,
                                            e.target.value.replace(/[^0-9.]/g, '').replaceAll(' ', '')
                                        );
                                    }}
                                />
                            </div>

                            <div className="flex flex-col justify-center">
                                <Field
                                    component={Switch}
                                    name="clientIsPep"
                                    label={t('common.formFields.clientIsPep')}
                                    className="mx-auto"
                                    helpText={t('pages.personalContactInformation.pepHelperText')}
                                    data-test="clientIsPep"
                                />
                                {values.clientIsPep && isF2F && (
                                    <Fragment>
                                        <Title
                                            tag="h3"
                                            size="md"
                                            fontWeight="medium"
                                            className="mt-12"
                                            data-test="pepQuestionnaireTitle"
                                        >
                                            {t('pages.personalContactInformation.pepQuestionnaire')}
                                        </Title>
                                        <DragAndDropUpload
                                            labelUpload={t<string>(
                                                'pages.personalContactInformation.dragAndDropQuestionnaire'
                                            )}
                                            multiple
                                            accept={SUPPORTED_UPLOAD_FILE_TYPES}
                                            onUpload={handleUploadPepFiles}
                                            data-test="pepFilesUpload"
                                        />
                                        <UploadedFiles files={pepFiles} onDelete={handleDeleteFile} />
                                    </Fragment>
                                )}
                            </div>
                        </Card>
                    </Form>
                </Fragment>
            )}
        </CustomFormik>
    );
};

export default PersonalDataForm;
