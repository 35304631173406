import React, { ChangeEvent, Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Formik, FormikProps } from 'formik';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { Card } from '../../../../components/common/Card';
import CardTitle from '../../../DamageEvent/CardTitle';
import { Grid } from '../../../../components/Grid';
import { Input } from '../../../../components/common/Input';
import { useNavigate, useParams } from 'react-router-dom';
import { callWaitingCaseDetail } from '../../../../apis/dashboard';
import {
    addDate,
    getBase64,
    getBase64FileContent,
    getFileExtension,
    getToken,
    isAllowedFileType,
    nowDate,
    subtractDate,
} from '../../../../utils';
import { WaitingCaseDetail as WaitingCaseDetailDto } from '../types';
import Loader from '../../../../components/common/Loader';
import { Error } from '../../../other/Error';
import { FormInputPlaceholder } from '../../../../components/common/FormInputPlaceholder';
import { HUMAN_DATE_FORMAT, REGEX, ROUTE } from '../../../../constants';
import SelectDocumentType from '../../../../components/SelectDocumentType';
import SelectIdentificatorType from '../../../../components/SelectIdentificatorType';
import SelectSex from '../../../../components/SelectSex';
import SelectCountry from '../../../../components/SelectCountry';
import { Switch } from '../../../../components/common/Switch';
import { FileInput } from '../../../../components/common/FileInput';
import { Button } from '../../../../components/common/Button';
import { callValidateClientData } from '../../../../apis/validations';
import { config } from '../../../../config';
import { MojeIdAddress, MojeIdBankAccount, MojeIdGetClientDataIdCard } from '../../../../types/model';
import { ErrorType } from '../../../../types';
import GlobalError from '../../../../components/GlobalError';
import { callAttachAttachmentToWorkflow } from '../../../../apis/documents';
import DateInput from '../../../../components/common/DateInput';
import DashboardLayout from './DashboardLayout';
import { AppContext } from '../../../../components/Context';
import { IdCardKind } from '../../../../types/enums/IdCardKind';
import CustomForm from '../../../../components/CustomForm';

interface WaitingCaseFormData {
    CaseBaseId?: number;
    InsertedDtt?: string;
    ExpirationDt?: string;
    BirthDate?: string;
    PhoneNumber?: string;
    Email?: string;
    IdentificationVerificationTypeName?: string;
    // id card
    CardDocumentType?: { value: number };
    CardIdNumber?: string;
    CardIssuedDt?: string;
    CardExpirationDt?: string;
    CardIssuer?: string;
    //
    FirstName?: string;
    LastName?: string;
    PersonalIdentifierType?: { value: number };
    PersonalIdentifier?: string;
    Sex?: { value: number };
    BirthDt?: string;
    BirthCountryCode?: { value: string };
    Birthplace?: string;
    CitizenshipCode?: { value: string };
    SecondCitizenshipCode?: { value: string };
    // trvalá adresa
    PermanentAddressStreetName?: string;
    PermanentAddressHouseNo?: string;
    PermanentAddressCityName?: string;
    PermanentAddressZip?: string;
    PermanentAddressCountryCode?: { value: string };
    // kontaktní adresa
    ContactAddressDifferent: boolean;
    ContactAddressStreetName?: string;
    ContactAddressHouseNo?: string;
    ContactAddressCityName?: string;
    ContactAddressZip?: string;
    ContactAddressCountryCode?: { value: string };
    // bankovní účet
    BankAccount?: string;
    // přílohy
    AttachmentFirstSide?: string;
    AttachmentSecondSide?: string;
}

const WaitingCaseDetail = () => {
    const { t } = useTranslation();
    const token = getToken();
    const navigate = useNavigate();
    const { caseId } = useParams();
    const myFormRef = useRef<FormikProps<WaitingCaseFormData>>(null);
    const { setCurrentFormik, setPopupForLeaveDirtyForm, ...ctx } = useContext(AppContext);
    const [data, setData] = useState<WaitingCaseDetailDto | null>(null);
    const [loading, setLoading] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [errors, setErrors] = useState<ErrorType[]>([]);
    const [attachmentFirstSide, setAttachmentFirstSide] = useState<File | null>(null);
    const [attachmentSecondSide, setAttachmentSecondSide] = useState<File | null>(null);

    const handleUploadAttachments = async (values: WaitingCaseFormData) => {
        const onlineAttachments = [];
        const method = values.CardDocumentType?.value;

        const attachmentFirstSideContent = attachmentFirstSide && (await getBase64(attachmentFirstSide));
        const attachmentSecondSideContent = attachmentSecondSide && (await getBase64(attachmentSecondSide));

        if (attachmentFirstSideContent) {
            onlineAttachments.push({
                attachmentData: getBase64FileContent(attachmentFirstSideContent),
                externalId: uuid(),
                fileName: `ID Card - front.${getFileExtension(attachmentFirstSide?.name) || 'png'}`,
                pageNumber: 1,
                onlinePolicyAttachmentTypeCode: 'IdCard',
            });
        }

        if (method === IdCardKind.IdentityCardPL && attachmentSecondSideContent) {
            onlineAttachments.push({
                attachmentData: getBase64FileContent(attachmentSecondSideContent),
                externalId: uuid(),
                fileName: `ID Card - back.${getFileExtension(attachmentSecondSide?.name) || 'png'}`,
                pageNumber: 2,
                onlinePolicyAttachmentTypeCode: 'IdCard',
            });
        }

        return onlineAttachments;
    };

    const save = async (d: WaitingCaseFormData) => {
        if (token && data && caseId) {
            setProcessing(true);

            const onlineAttachments = await handleUploadAttachments(d);

            if (onlineAttachments.length === 0) {
                setProcessing(false);
                return;
            }

            const idCard = {
                Kind: d.CardDocumentType?.value,
                IdCardNo: d.CardIdNumber,
                IssuedDt: d.CardIssuedDt,
                ExpiryDt: d.CardExpirationDt,
                Issuer: d.CardIssuer,
            } as MojeIdGetClientDataIdCard;

            const IdCards = (data.ClientPersonDto.IdCards || []) as MojeIdGetClientDataIdCard[];
            if (IdCards.length === 0) {
                IdCards.push(idCard);
            } else {
                IdCards[0] = idCard;
            }

            const permanentAddress = {
                StreetName: d.PermanentAddressStreetName,
                HouseNo: d.PermanentAddressHouseNo,
                CityName: d.PermanentAddressCityName,
                Zip: d.PermanentAddressZip,
                CountryCode: d.PermanentAddressCountryCode?.value,
            } as MojeIdAddress;

            const PermanentAddresses = (data.ClientPersonDto.PermanentAddresses || []) as MojeIdAddress[];
            if (PermanentAddresses.length === 0) {
                PermanentAddresses.push(permanentAddress);
            } else {
                PermanentAddresses[0] = permanentAddress;
            }

            const ContactAddresses = (data.ClientPersonDto.ContactAddresses || []) as MojeIdAddress[];
            if (d.ContactAddressDifferent) {
                const contactAddress = {
                    StreetName: d.ContactAddressStreetName,
                    HouseNo: d.ContactAddressHouseNo,
                    CityName: d.ContactAddressCityName,
                    Zip: d.ContactAddressZip,
                    CountryCode: d.ContactAddressCountryCode?.value,
                } as MojeIdAddress;

                if (ContactAddresses.length === 0) {
                    ContactAddresses.push(contactAddress);
                } else {
                    ContactAddresses[0] = contactAddress;
                }
            }

            const bankAccount = {
                Iban: d.BankAccount,
                Code: '',
                BankAccountNo: '',
                Prefix: '',
                BankCode: '',
            } as MojeIdBankAccount;

            const BankAccounts = (data.ClientPersonDto.BankAccounts || []) as MojeIdBankAccount[];
            if (BankAccounts.length === 0) {
                BankAccounts.push(bankAccount);
            } else {
                BankAccounts[0] = bankAccount;
            }

            const clientPerson = {
                ...data.ClientPersonDto,
                PersonalIdentification: {
                    ...data.ClientPersonDto.PersonalIdentification,
                    FirstName: d.FirstName,
                    LastName: d.LastName,
                    PersonalIdentifierType: d.PersonalIdentifierType?.value,
                    PersonalIdentifier: d.PersonalIdentifier,
                    Sex: d.Sex?.value,
                    BirthDt: d.BirthDt,
                    BirthCountryCode: d.BirthCountryCode?.value,
                    Birthplace: d.Birthplace,
                    CitizenshipCode: d.CitizenshipCode?.value,
                    SecondCitizenshipCode: d.SecondCitizenshipCode?.value,
                },
                IdCards,
                PermanentAddresses,
                ContactAddresses,
                BankAccounts: d.BankAccount ? BankAccounts : null,
            };

            // validace
            callValidateClientData({
                data: {
                    clientPersonDtoJson: JSON.stringify(clientPerson),
                },
                token,
            })
                .then((response) => response.data)
                .then((result) => {
                    const error: ErrorType[] = [];

                    // kontrola peselu a rodného čísla
                    const vPesel = result?.ValidatePeselByBirthDateAndGenderResult;
                    if (vPesel?.IsPeselValidByBirthDate === false || vPesel?.IsPeselValidByCheckSum === false) {
                        error.push({
                            code: '',
                            message: t('pages.personalContactInformation.errors.isValidByPesel'),
                        });
                    }

                    if (vPesel?.IsPeselValidByGender === false) {
                        error.push({
                            code: '',
                            message: t('pages.personalContactInformation.errors.isPeselValidByGender'),
                        });
                    }

                    // kontrola osobních údajů
                    const vPersonal = result?.ValidateClientPersonalInformationExistResult;
                    if (vPersonal?.IsValidByPesel === false) {
                        error.push({
                            code: '',
                            message: t('pages.personalContactInformation.errors.isValidByPesel'),
                        });
                    }
                    if (vPersonal?.IsValidByEmailAndPhone === false) {
                        error.push({
                            code: '',
                            message: t('pages.personalContactInformation.errors.isValidByEmailAndPhone'),
                        });
                    }

                    // kontrola sakcionovaných seznamů
                    if (result?.ValidateSanctionResult?.IsValid !== true) {
                        error.push({
                            code: '',
                            message: t('pages.personalContactInformation.errors.isFromSanctionRegion'),
                        });
                    }

                    // nastavení chyb do formuláře
                    setErrors(error);

                    // bez chyb
                    if (error.length === 0) {
                        // potvrdím změnu dat
                        callAttachAttachmentToWorkflow({
                            data: {
                                caseBaseExternalId: caseId,
                                clientPersonDtoJson: JSON.stringify(clientPerson),
                                onlineAttachments,
                            },
                            token,
                        })
                            .then((response) => response.data)
                            .then(() => {
                                ctx.showPopup('waiting-case-saved');
                                navigate(ROUTE.DASHBOARD_WAITING_CASE);
                            })
                            .finally(() => setProcessing(false));
                    } else {
                        setProcessing(false);
                    }
                })
                .catch(() => {
                    setProcessing(false);
                });
        }
    };

    const handleFormSubmit = (d: WaitingCaseFormData) => {
        ctx.showPopup('waiting-case-submit', () => {
            save(d);
        });
    };

    useEffect(() => {
        if (token && caseId) {
            setLoading(true);
            callWaitingCaseDetail({
                data: {
                    caseExternalId: caseId,
                },
                token,
            })
                .then((response) => response.data)
                .then((result) => setData(result))
                .finally(() => setLoading(false));
        }
    }, [caseId, token]);

    const idCard = (data?.ClientPersonDto?.IdCards || []).length > 0 ? data?.ClientPersonDto.IdCards[0] : null;
    const permanentAddress =
        (data?.ClientPersonDto?.PermanentAddresses || []).length > 0
            ? data?.ClientPersonDto.PermanentAddresses[0]
            : null;
    const contactAddress =
        (data?.ClientPersonDto?.ContactAddresses || []).length > 0 ? data?.ClientPersonDto.ContactAddresses[0] : null;
    const bankAccount =
        (data?.ClientPersonDto?.BankAccounts || []).length > 0 ? data?.ClientPersonDto.BankAccounts[0] : null;

    const formSchema = Yup.object().shape({
        // id card
        CardDocumentType: Yup.object().required(),
        CardIdNumber: Yup.string()
            .required()
            .validateIdNumber(
                t('common.formErrors.idDetailNotValid'),
                t('common.formErrors.passportNotValid'),
                'CardDocumentType'
            ),
        CardIssuedDt: Yup.date().required().max(new Date()).min(subtractDate(config.MAX_DOCUMENT_AGE, 'years')),
        CardExpirationDt: Yup.date().required().min(new Date()).max(addDate(config.MAX_DOCUMENT_AGE, 'years')),
        CardIssuer: Yup.string().required().min(2),
        // osobni udaje
        FirstName: Yup.string().required().min(2),
        LastName: Yup.string().required().min(2),
        PersonalIdentifier: Yup.string()
            .peselFormat(t('common.formErrors.wrongPesel'))
            .compareBirthDtPesel('BirthDt', t('common.formErrors.peselDontMatchBirthDt'))
            .required(),
        PersonalIdentifierType: Yup.object().required(),
        Sex: Yup.object().required(),
        BirthDt: Yup.date()
            .required()
            .min(subtractDate(config.MAX_AGE, 'years'))
            .max(subtractDate(config.MIN_AGE, 'years')),
        BirthCountryCode: Yup.object().required(),
        Birthplace: Yup.string().required().min(2),
        CitizenshipCode: Yup.object().required(),
        SecondCitizenshipCode: Yup.object().nullable(),
        // trvalá adresa
        PermanentAddressStreetName: Yup.string().required(),
        PermanentAddressHouseNo: Yup.string().required(),
        PermanentAddressCityName: Yup.string().required().min(2),
        PermanentAddressZip: Yup.string()
            .required()
            .matches(new RegExp(REGEX.POLISH_POST_REGEX), t('common.formErrors.wrongPostalCode') || ''),
        PermanentAddressCountryCode: Yup.object().required(),
        // kontaktní adresa
        ContactAddressStreetName: Yup.string().when('ContactAddressDifferent', {
            is: (ContactAddressDifferent: boolean) => !!ContactAddressDifferent,
            then: (schema) => schema.required(),
        }),
        ContactAddressHouseNo: Yup.string().when('ContactAddressDifferent', {
            is: (ContactAddressDifferent: boolean) => !!ContactAddressDifferent,
            then: (schema) => schema.required(),
        }),
        ContactAddressCityName: Yup.string().when('ContactAddressDifferent', {
            is: (ContactAddressDifferent: boolean) => !!ContactAddressDifferent,
            then: (schema) => schema.required().min(2),
            otherwise: (schema) => schema.min(2),
        }),
        ContactAddressZip: Yup.string().when('ContactAddressDifferent', {
            is: (ContactAddressDifferent: boolean) => !!ContactAddressDifferent,
            then: (schema) =>
                schema
                    .required()
                    .matches(new RegExp(REGEX.POLISH_POST_REGEX), t('common.formErrors.wrongPostalCode') || ''),
            otherwise: (schema) => schema.min(2),
        }),
        ContactAddressCountryCode: Yup.object().when('ContactAddressDifferent', {
            is: (ContactAddressDifferent: boolean) => !!ContactAddressDifferent,
            then: (schema) => schema.required(),
        }),
        // bankovní účet
        BankAccount: Yup.string()
            .nullable()
            .matches(new RegExp(REGEX.POLISH_BANK_NUMBER), t('common.formErrors.wrongBankAccountNumber') || ''),
        // přílohy
        AttachmentFirstSide: Yup.string()
            .required()
            .test(`attachmentFirstSide`, function (value) {
                const { path, createError } = this;
                const file = attachmentFirstSide;
                if (file) {
                    if (file?.size > config.MAX_UPLOAD_SIZE) {
                        return createError({ path, message: t<string>('pages.documentPhoto.largeFileSize') });
                    } else if (!isAllowedFileType(file)) {
                        return createError({ path, message: t<string>('pages.documentPhoto.wrongFileFormat') });
                    }
                }
                return true;
            }),
        AttachmentSecondSide: Yup.string()
            .nullable()
            .when('CardDocumentType', {
                is: (documentType: { value: number }) => documentType?.value === IdCardKind.IdentityCardPL,
                then: (schema) => schema.required(),
            })
            .test(`attachmentSecondSide`, function (value) {
                const { path, createError } = this;
                const file = attachmentSecondSide;
                const firstFile = attachmentFirstSide;
                if (file) {
                    if (file?.name === firstFile?.name) {
                        return createError({ path, message: t<string>('pages.documentPhoto.sameFileError') });
                    } else if (file?.size > config.MAX_UPLOAD_SIZE) {
                        return createError({ path, message: t<string>('pages.documentPhoto.largeFileSize') });
                    } else if (!isAllowedFileType(file)) {
                        return createError({ path, message: t<string>('pages.documentPhoto.wrongFileFormat') });
                    }
                }
                return true;
            }),
    });

    useEffect(() => {
        setCurrentFormik(myFormRef);
        setPopupForLeaveDirtyForm('cancel-waiting-case');
        return () => {
            setCurrentFormik(null);
            setPopupForLeaveDirtyForm(null);
        };
    }, [setCurrentFormik, setPopupForLeaveDirtyForm, myFormRef]);

    if (loading) {
        return <Loader />;
    }

    if (!data) {
        return <Error type="not-found" />;
    }

    const personalInputsDisabled = data.CasePrevaluationDto.ClientIdentificationVerificationTypeId === 1;

    return (
        <Fragment>
            {processing && <Loader overflow />}

            <Formik<WaitingCaseFormData>
                initialValues={{
                    CaseBaseId: data.CasePrevaluationDto.CaseBaseId,
                    InsertedDtt: data.CasePrevaluationDto.InsertedDtt,
                    ExpirationDt: data.CasePrevaluationDto.ExpirationDt,
                    BirthDate: data.CasePrevaluationDto.OwnerBirthDt,
                    PhoneNumber: `${data.CasePrevaluationDto.OwnerPhoneNumberPrefixConfirmed} ${data.CasePrevaluationDto.OwnerPhoneNumberConfirmed}`,
                    Email: data.CasePrevaluationDto.OwnerEmailConfirmed,
                    IdentificationVerificationTypeName:
                        data.CasePrevaluationDto.ClientIdentificationVerificationTypeName,
                    // doklad
                    CardDocumentType: idCard ? { value: idCard?.Kind } : { value: IdCardKind.IdentityCardPL },
                    CardIdNumber: idCard?.IdCardNo,
                    CardIssuedDt: idCard?.IssuedDt,
                    CardExpirationDt: idCard?.ExpiryDt,
                    CardIssuer: idCard?.Issuer,
                    // osobní údaje
                    FirstName: data?.ClientPersonDto?.PersonalIdentification?.FirstName,
                    LastName: data?.ClientPersonDto?.PersonalIdentification?.LastName,
                    PersonalIdentifierType: data?.ClientPersonDto?.PersonalIdentification?.PersonalIdentifierType
                        ? { value: data?.ClientPersonDto?.PersonalIdentification?.PersonalIdentifierType }
                        : { value: 5 },
                    PersonalIdentifier: data?.ClientPersonDto?.PersonalIdentification?.PersonalIdentifier,
                    Sex:
                        data?.ClientPersonDto?.PersonalIdentification?.Sex &&
                        data?.ClientPersonDto?.PersonalIdentification?.Sex !== 1
                            ? { value: data?.ClientPersonDto?.PersonalIdentification?.Sex }
                            : undefined,
                    BirthDt: data?.ClientPersonDto?.PersonalIdentification?.BirthDt,
                    BirthCountryCode: data?.ClientPersonDto?.PersonalIdentification?.BirthCountryCode
                        ? { value: data?.ClientPersonDto?.PersonalIdentification?.BirthCountryCode }
                        : undefined,
                    Birthplace: data?.ClientPersonDto?.PersonalIdentification?.Birthplace,
                    CitizenshipCode: data?.ClientPersonDto?.PersonalIdentification?.CitizenshipCode
                        ? { value: data?.ClientPersonDto?.PersonalIdentification?.CitizenshipCode }
                        : undefined,
                    SecondCitizenshipCode: data?.ClientPersonDto?.PersonalIdentification?.SecondCitizenshipCode
                        ? { value: data?.ClientPersonDto?.PersonalIdentification?.SecondCitizenshipCode }
                        : undefined,
                    // trvalá adresa
                    PermanentAddressStreetName: permanentAddress?.StreetName,
                    PermanentAddressHouseNo: permanentAddress?.HouseNo,
                    PermanentAddressCityName: permanentAddress?.CityName,
                    PermanentAddressZip: permanentAddress?.Zip,
                    PermanentAddressCountryCode: permanentAddress?.CountryCode
                        ? { value: permanentAddress?.CountryCode }
                        : undefined,
                    // kontaktní adresa
                    ContactAddressDifferent: data.ClientPersonDto.ContactAddresses.length > 0,
                    ContactAddressStreetName: contactAddress?.StreetName,
                    ContactAddressHouseNo: contactAddress?.HouseNo,
                    ContactAddressCityName: contactAddress?.CityName,
                    ContactAddressZip: contactAddress?.Zip,
                    ContactAddressCountryCode: contactAddress?.CountryCode
                        ? { value: contactAddress?.CountryCode }
                        : undefined,
                    // bankovní účet
                    BankAccount: bankAccount?.Iban,
                    // přílohy
                    AttachmentFirstSide: undefined,
                    AttachmentSecondSide: undefined,
                }}
                validationSchema={formSchema}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={myFormRef}
                onSubmit={handleFormSubmit}
            >
                {(formikProps) => {
                    const { values, dirty, setFieldValue, handleSubmit } = formikProps;
                    console.log(values);
                    return (
                        <DashboardLayout
                            backButton={{
                                display: true,
                                onClick: () => {
                                    if (dirty) {
                                        ctx.showPopup('cancel-waiting-case', () => {
                                            navigate(ROUTE.DASHBOARD_WAITING_CASE);
                                        });
                                    } else {
                                        navigate(ROUTE.DASHBOARD_WAITING_CASE);
                                    }
                                },
                            }}
                        >
                            {errors && <GlobalError errors={errors} data-test="error" />}
                            <CustomForm {...formikProps} disableScrollToErrors={false}>
                                <div className="grid gap-y-4">
                                    <Card variant="secondary" hasPadding={false} className="gap-y-5 p-5 px-7">
                                        <CardTitle>{t('common.basicInformation')}</CardTitle>
                                        <Grid cols={3} className="!gap-x-4">
                                            <FormInputPlaceholder title={t('dashboard.waitingCase.identificator')}>
                                                {values.CaseBaseId}
                                            </FormInputPlaceholder>
                                            <FormInputPlaceholder title={t('dashboard.waitingCase.insertedDate')}>
                                                {dayjs(values.InsertedDtt).format(HUMAN_DATE_FORMAT)}
                                            </FormInputPlaceholder>
                                            <FormInputPlaceholder title={t('dashboard.waitingCase.expirationDate')}>
                                                {dayjs(values.ExpirationDt).format(HUMAN_DATE_FORMAT)}
                                            </FormInputPlaceholder>
                                        </Grid>
                                        <Grid cols={3} className="!gap-x-4">
                                            <FormInputPlaceholder title={t('dashboard.waitingCase.birthDate')}>
                                                {dayjs(values.BirthDate).format(HUMAN_DATE_FORMAT)}
                                            </FormInputPlaceholder>
                                            <FormInputPlaceholder title={t('dashboard.waitingCase.phoneNumber')}>
                                                {values.PhoneNumber}
                                            </FormInputPlaceholder>
                                            <FormInputPlaceholder title={t('dashboard.waitingCase.emailAddress')}>
                                                {values.Email}
                                            </FormInputPlaceholder>
                                        </Grid>
                                        <Grid cols={3} className="!gap-x-4">
                                            <FormInputPlaceholder
                                                title={t('dashboard.waitingCase.identifyVerificationType')}
                                            >
                                                {values.IdentificationVerificationTypeName}
                                            </FormInputPlaceholder>
                                        </Grid>
                                    </Card>
                                    <Card variant="secondary" hasPadding={false} className="gap-y-5 p-5 px-7">
                                        <CardTitle>{t('common.personalIdCard')}</CardTitle>
                                        <Grid cols={3} className="!gap-x-4">
                                            <Field
                                                name="CardDocumentType"
                                                label={t('common.formFields.documentType')}
                                                component={SelectDocumentType}
                                                isDisabled={personalInputsDisabled}
                                            />
                                            <Field
                                                name="CardIdNumber"
                                                label={t('common.formFields.idNumber')}
                                                component={Input}
                                                isDisabled={personalInputsDisabled}
                                            />
                                            <Field
                                                name="CardIssuedDt"
                                                label={t('common.formFields.issueDate')}
                                                component={DateInput}
                                                isDisabled={personalInputsDisabled}
                                                minDate={new Date(subtractDate(config.MAX_DOCUMENT_AGE, 'years'))}
                                                maxDate={new Date(nowDate())}
                                            />
                                        </Grid>
                                        <Grid cols={3} className="!gap-x-4">
                                            <Field
                                                name="CardExpirationDt"
                                                label={t('common.formFields.expirationDate')}
                                                component={DateInput}
                                                isDisabled={personalInputsDisabled}
                                                minDate={new Date(addDate(1, 'days'))}
                                                maxDate={new Date(addDate(config.MAX_DOCUMENT_AGE, 'years'))}
                                            />
                                            <Field
                                                name="CardIssuer"
                                                label={t('common.formFields.issuedBy')}
                                                component={Input}
                                                isDisabled={personalInputsDisabled}
                                            />
                                        </Grid>
                                    </Card>
                                    <Card variant="secondary" hasPadding={false} className="gap-y-5 p-5 px-7">
                                        <CardTitle>{t('common.personalInformation')}</CardTitle>
                                        <Grid cols={3} className="!gap-x-4">
                                            <Field
                                                name="FirstName"
                                                label={t('common.formFields.nameWithoutComments')}
                                                component={Input}
                                                isDisabled={personalInputsDisabled}
                                            />
                                            <Field
                                                name="LastName"
                                                label={t('common.formFields.surname')}
                                                component={Input}
                                                isDisabled={personalInputsDisabled}
                                            />
                                            <Field
                                                name="PersonalIdentifierType"
                                                label={t('common.formFields.identificatorType')}
                                                component={SelectIdentificatorType}
                                                isDisabled
                                            />
                                        </Grid>
                                        <Grid cols={3} className="!gap-x-4">
                                            <Field
                                                name="PersonalIdentifier"
                                                label={t('common.formFields.identificatorNumber')}
                                                component={Input}
                                                isDisabled={personalInputsDisabled}
                                            />
                                            <Field
                                                name="Sex"
                                                label={t('common.formFields.sex')}
                                                component={SelectSex}
                                                isDisabled={personalInputsDisabled}
                                            />
                                            <Field
                                                name="BirthDt"
                                                label={t('common.formFields.birthDate')}
                                                component={DateInput}
                                                isDisabled
                                            />
                                        </Grid>
                                        <Grid cols={3} className="!gap-x-4">
                                            <Field
                                                name="BirthCountryCode"
                                                label={t('common.formFields.stateOfBirth')}
                                                component={SelectCountry}
                                                isDisabled={personalInputsDisabled}
                                            />
                                            <Field
                                                name="Birthplace"
                                                label={t('common.formFields.birthCity')}
                                                component={Input}
                                                isDisabled={personalInputsDisabled}
                                            />
                                            <Field
                                                name="CitizenshipCode"
                                                label={t('common.formFields.citizenship')}
                                                component={SelectCountry}
                                                isDisabled={personalInputsDisabled}
                                            />
                                        </Grid>
                                        <Grid cols={3} className="!gap-x-4">
                                            <Field
                                                name="SecondCitizenshipCode"
                                                label={t('common.formFields.secondCitizenship')}
                                                component={SelectCountry}
                                                isDisabled={personalInputsDisabled}
                                            />
                                        </Grid>
                                    </Card>
                                    <Card variant="secondary" hasPadding={false} className="gap-y-5 p-5 px-7">
                                        <CardTitle>{t('common.registeredAddress')}</CardTitle>
                                        <Grid cols={3} className="!gap-x-4">
                                            <Field
                                                name="PermanentAddressStreetName"
                                                label={t('common.formFields.street')}
                                                component={Input}
                                                isDisabled={personalInputsDisabled}
                                            />
                                            <Field
                                                name="PermanentAddressHouseNo"
                                                label={t('common.formFields.houseNumber')}
                                                component={Input}
                                                isDisabled={personalInputsDisabled}
                                            />
                                            <Field
                                                name="PermanentAddressCityName"
                                                label={t('common.formFields.city')}
                                                component={Input}
                                                isDisabled={personalInputsDisabled}
                                            />
                                        </Grid>
                                        <Grid cols={3} className="!gap-x-4">
                                            <Field
                                                name="PermanentAddressZip"
                                                label={t('common.formFields.postalCode')}
                                                component={Input}
                                                isDisabled={personalInputsDisabled}
                                            />
                                            <Field
                                                name="PermanentAddressCountryCode"
                                                label={t('common.formFields.country')}
                                                component={SelectCountry}
                                                isDisabled={personalInputsDisabled}
                                            />
                                        </Grid>
                                        <Grid cols={1} className="!gap-x-4">
                                            <Field
                                                name="ContactAddressDifferent"
                                                label={t('common.formFields.contacAddressDifferent')}
                                                component={Switch}
                                                isDisabled={personalInputsDisabled}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    setFieldValue('ContactAddressDifferent', e.target.checked);
                                                    if (!e.target.checked) {
                                                        setFieldValue('ContactAddressStreetName', '');
                                                        setFieldValue('ContactAddressHouseNo', '');
                                                        setFieldValue('ContactAddressCityName', '');
                                                        setFieldValue('ContactAddressZip', '');
                                                        setFieldValue('ContactAddressCountryCode', '');
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        {values.ContactAddressDifferent && (
                                            <Fragment>
                                                <CardTitle>{t('common.contactAddress')}</CardTitle>
                                                <Grid cols={3} className="!gap-x-4">
                                                    <Field
                                                        name="ContactAddressStreetName"
                                                        label={t('common.formFields.street')}
                                                        component={Input}
                                                        isDisabled={personalInputsDisabled}
                                                    />
                                                    <Field
                                                        name="ContactAddressHouseNo"
                                                        label={t('common.formFields.houseNumber')}
                                                        component={Input}
                                                        isDisabled={personalInputsDisabled}
                                                    />
                                                    <Field
                                                        name="ContactAddressCityName"
                                                        label={t('common.formFields.city')}
                                                        component={Input}
                                                        isDisabled={personalInputsDisabled}
                                                    />
                                                </Grid>
                                                <Grid cols={3} className="!gap-x-4">
                                                    <Field
                                                        name="ContactAddressZip"
                                                        label={t('common.formFields.postalCode')}
                                                        component={Input}
                                                        isDisabled={personalInputsDisabled}
                                                    />
                                                    <Field
                                                        name="ContactAddressCountryCode"
                                                        label={t('common.formFields.country')}
                                                        component={SelectCountry}
                                                        isDisabled={personalInputsDisabled}
                                                    />
                                                </Grid>
                                            </Fragment>
                                        )}
                                    </Card>
                                    <Card variant="secondary" hasPadding={false} className="gap-y-5 p-5 px-7">
                                        <CardTitle>{t('common.bankAccount')}</CardTitle>
                                        <Grid cols={1} className="!gap-x-4">
                                            <Field
                                                name="BankAccount"
                                                label={t('common.formFields.bankAccountNumber')}
                                                component={Input}
                                                isDisabled={personalInputsDisabled}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    setFieldValue('BankAccount', e.target.value.replace(/\D/g, ''));
                                                }}
                                            />
                                        </Grid>
                                    </Card>
                                    <Card variant="secondary" hasPadding={false} className="gap-y-5 p-5 px-7">
                                        {values.CardDocumentType?.value === IdCardKind.IdentityCardPL ? (
                                            <CardTitle>{t('dashboard.waitingCase.attachmentTitle.idCard')}</CardTitle>
                                        ) : (
                                            <CardTitle>{t('dashboard.waitingCase.attachmentTitle.passport')}</CardTitle>
                                        )}
                                        <Grid cols={3} className="!gap-x-4">
                                            <Field
                                                name="AttachmentFirstSide"
                                                label={
                                                    values.CardDocumentType?.value === IdCardKind.IdentityCardPL
                                                        ? t('common.formFields.firstSide')
                                                        : t('common.formFields.passportSide')
                                                }
                                                component={FileInput}
                                                onChange={(e: any) => {
                                                    setAttachmentFirstSide(e?.target?.files?.[0] || null);
                                                    setFieldValue('AttachmentFirstSide', e?.target?.files?.[0] || null);
                                                }}
                                            />
                                            {values.CardDocumentType?.value === IdCardKind.IdentityCardPL ? (
                                                <Field
                                                    name="AttachmentSecondSide"
                                                    label={t('common.formFields.secondSide')}
                                                    component={FileInput}
                                                    onChange={(e: any) => {
                                                        setAttachmentSecondSide(e?.target?.files?.[0] || null);
                                                        setFieldValue(
                                                            'AttachmentSecondSide',
                                                            e?.target?.files?.[0] || null
                                                        );
                                                    }}
                                                />
                                            ) : (
                                                <Fragment />
                                            )}
                                        </Grid>
                                    </Card>
                                    <div>
                                        <Button
                                            size="lg"
                                            className="m-auto"
                                            isDisabled={!dirty}
                                            onClick={() => {
                                                setErrors([]);
                                                handleSubmit();
                                            }}
                                        >
                                            {t('common.finishCase')}
                                        </Button>
                                    </div>
                                </div>
                            </CustomForm>
                        </DashboardLayout>
                    );
                }}
            </Formik>
        </Fragment>
    );
};

export default WaitingCaseDetail;
